body {
  margin: auto;
  width: 80%;
  height: 100%;
  padding: 0;
  font-family: sans-serif;
  background: #eeeeee;
  color: #111111;
  box-sizing: border-box;
}

p {
  display: block;
  text-align: center;
  padding: 10px;
}

.container {
  background-color: #ffffff;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  -webkit-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}

h3 {
  font-size: 3rem;
}
/* ------------- Responsive Design ------------------- */

@media (min-width: 768px) and (max-width: 1024px) {
  body {
    margin: auto;
    width: 100%;
    height: 100%;
    padding: 0;
    font-family: sans-serif;
    background: #eeeeee;
    color: #111111;
    box-sizing: border-box;
  }

  .container {
    background-color: #ffffff;
    width: 100%;
    /* max-width: 1500px; */
    margin: 0 auto;
    box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}

  nav {
    text-align: center;
    display: grid;
    width: 100% !important;
    grid-template-columns: 1fr;
  }

  nav ul {
    width: 100% !important;
    display: flex !important;
    margin-bottom: 10px !important;
    padding: 0px !important;
    margin: -4px -77px 0px -50px !important;
  }

  nav ul:first-of-type li {
    font-size: 2rem !important;
    font-weight: bold;
    text-indent: 95px !important;
  }

  nav ul:last-of-type li {
   text-align: center;
   width: 100%;
   padding-top: 10px;
   margin: 0px 10px 0 0px !important;
  }

  nav ul li a {
    font-size: 1.2rem !important;
}

  header {
    background-size: 160%;
    height: 270px;
    position: absolute;
  }

  header div.top {
    width: 75%;
    grid-template-columns: 1fr;
    padding-top: 260px;
  }

  header div.top h1 {
    font-size: 3rem !important;
    border: none;
    padding: 20px;
    margin: 0 0 11px -40px !important;
    letter-spacing: 2px !important;
  }

  header div.top p {
    padding-left: 0 !important;
    display: none !important;
  }

  main section:first-of-type {
    margin-top: 390px;
  }

  main section {
    width: 90%;
    padding: 90px 0 !important;
    margin: 50px auto 0px auto;
    display: grid;
    grid-template-columns: 1fr !important;
  }

  main section h2 {
    border-right: 0px solid #dddddd !important;
    line-height: 0.2rem !important;
    margin-right: 35px !important;
    font-size: 3rem !important;
    margin-left: 0px !important;
  }

  main section div p {
    padding: 0px 0px;
    font-size: 1.2rem;
    padding-left: 0px !important;
  }

  div.services {
   display: grid;
   grid-template-columns: 1fr !important;
  }

  div.services div {
    width: 90%;
    height: 90%;
  }

  .services p.service__icon {
    font-size: 2.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
  }

  .services p.service__title {
    font-size: 2rem !important;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }

  .services p:last-of-type{
    padding: 0px 20px; 
    text-align: justify;
  }

  .gallery {
    /* grid-template-columns: 1fr; */
    width: 100%;
    grid-template-columns: 0 !important;
    height: 2165px !important;
    border: 1px solid #333333;
    margin: 150px auto 0px auto !important;
  }

  .gallery div {
    height: 200px;
  }

  .gallery div.gallery__item__one {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span !important;
   grid-column-end: 2 span !important;
  }

  .gallery div.gallery__item__two {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__three {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__four {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__five {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-top: 100px;
   padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__six {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-top: 100px;
   padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  main section form div label {
    margin-right:10px;
    border-radius: 5px;
  }

  main section form div:last-of-type input {
    padding: 10px;
    border-radius: 5px;
  }

  form {
    margin: 0px 0px 0px -25px !important;
    padding: 10px !important;
  }

  .form__h2 {
    margin-left: -22px !important;
  }

  footer {
    padding: 20px !important;
    text-align: center;
    display: grid !important;
    grid-template-columns: 1fr !important;
  }

  footer ul {
    margin: -15px !important;
  }

  footer ul li a {
    font-size: 4rem !important;
  }

  h3 {
    font-size: 2.5rem !important;
    font-weight: bold !important;
    margin-block-start: 0.2rem !important;
    margin-block-end: 0.2rem !important;
  }
  
  p {
    text-align: center !important;
    font-size: 1.2rem;
  }
  
  .contact {
    display: none !important;
  }

}

@media (min-width: 481px) and (max-width: 767px) {

  body {
    margin: auto;
    width: 100%;
    height: 100%;
    padding: 0;
    font-family: sans-serif;
    background: #eeeeee;
    color: #111111;
    box-sizing: border-box;
  }

  .container {
    background-color: #ffffff;
    width: 100%;
    /* max-width: 1500px; */
    margin: 0 auto;
    box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}

  nav {
    text-align: center;
    display: grid;
    width: 100% !important;
    grid-template-columns: 1fr;
  }

  nav ul {
    width: 100% !important;
    display: flex !important;
    margin-bottom: 10px !important;
    padding: 0px !important;
    margin: -4px -77px 0px -85px !important;
  }

  nav ul:first-of-type li {
    font-size: 2rem !important;
    font-weight: bold;
    text-indent: 95px !important;
  }

  nav ul:last-of-type li {
   text-align: center;
   width: 100%;
   padding-top: 10px;
   margin: 0px 10px 0 0px !important;
  }

  nav ul li a {
    font-size: 1.2rem !important;
}

  header {
    background-size: 160%;
    height: 270px;
    position: absolute;
  }

  header div.top {
    width: 75%;
    grid-template-columns: 1fr;
    padding-top: 260px;
  }

  header div.top h1 {
    font-size: 3rem !important;
    border: none;
    padding: 20px;
    margin: 0 0 11px -40px !important;
    letter-spacing: 2px !important;
  }

  header div.top p {
    padding-left: 0 !important;
    display: none !important;
  }

  main section:first-of-type {
    margin-top: 390px;
  }

  main section {
    width: 90%;
    padding: 90px 0 !important;
    margin: 50px auto 0px auto;
    display: grid;
    grid-template-columns: 1fr !important;
  }

  main section h2 {
    border-right: 0px solid #dddddd !important;
    line-height: 0.2rem !important;
    margin-right: 35px !important;
    font-size: 3rem !important;
    margin-left: 0px !important;
  }

  main section div p {
    padding: 0px 0px;
    font-size: 1.2rem;
    padding-left: 0px !important;
  }

  div.services {
   display: grid;
   grid-template-columns: 1fr !important;
  }

  div.services div {
    width: 90%;
    height: 90%;
  }

  .services p.service__icon {
    font-size: 2.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
  }

  .services p.service__title {
    font-size: 2rem !important;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }

  .services p:last-of-type{
    padding: 0px 20px; 
    text-align: justify;
  }

  .gallery {
    /* grid-template-columns: 1fr; */
    width: 100%;
    grid-template-columns: 0 !important;
    height: 2165px !important;
    border: 1px solid #333333;
    margin: 150px auto 0px auto !important;
  }

  .gallery div {
    height: 200px;
  }

  .gallery div.gallery__item__one {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span !important;
   grid-column-end: 2 span !important;
  }

  .gallery div.gallery__item__two {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__three {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__four {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__five {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-top: 100px;
   padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__six {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-top: 100px;
   padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  main section form div label {
    margin-right:10px;
    border-radius: 5px;
  }

  main section form div:last-of-type input {
    padding: 10px;
    border-radius: 5px;
  }

  form {
    margin: 0px 0px 0px -25px !important;
    padding: 10px !important;
  }

  .form__h2 {
    margin-left: -22px !important;
  }

  footer {
    padding: 20px !important;
    text-align: center;
    display: grid !important;
    grid-template-columns: 1fr !important;
  }

  footer ul {
    margin: -15px !important;
  }

  footer ul li a {
    font-size: 4rem !important;
  }

  h3 {
    font-size: 2.5rem !important;
    font-weight: bold !important;
    margin-block-start: 0.2rem !important;
    margin-block-end: 0.2rem !important;
  }
  
  p {
    text-align: center !important;
    font-size: 1.2rem;
  }
  
  .contact {
    display: none !important;
  }

}


@media (min-width: 1px) and (max-width: 480px)  {

  body {
    margin: auto;
    width: 100%;
    height: 100%;
    padding: 0;
    font-family: sans-serif;
    background: #eeeeee;
    color: #111111;
    box-sizing: border-box;
  }

  .container {
    background-color: #ffffff;
    width: 100%;
    /* max-width: 1500px; */
    margin: 0 auto;
    box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}

  nav {
    text-align: center;
    display: grid;
    width: 100% !important;
    grid-template-columns: 1fr;
  }

  nav ul {
    width: 100% !important;
    display: flex !important;
    margin-bottom: 10px !important;
    padding: 0px !important;
    margin: -4px -77px 0px -85px !important;
  }

  nav ul:first-of-type li {
    font-size: 1.2rem !important;
    font-weight: bold;
    text-indent: 95px !important;
    margin: 0px -50px 0 0px !important;
  }

  nav ul:last-of-type li {
   text-align: center;
   width: 100%;
   padding-top: 10px;
   margin: 0px 10px 0 0px !important;
  }

  nav ul li a {
    font-size: 0.9rem !important;
}

  header {
    background-size: 160%;
    height: 270px;
    position: absolute;
  }

  header div.top {
    width: 75%;
    grid-template-columns: 1fr;
    padding-top: 260px;
  }

  header div.top h1 {
    font-size: 2rem !important;
    border: none;
    padding: 58px;
    margin: 0 0 0 -65px !important;
    letter-spacing: -1px !important;
  }

  header div.top p {
    padding-left: 0 !important;
    display: none !important;
  }

  main section:first-of-type {
    margin-top: 390px;
  }

  main section {
    width: 90%;
    padding: 90px 0 !important;
    margin: 50px auto 0px auto;
    display: grid;
    grid-template-columns: 1fr !important;
  }

  main section h2 {
    border-right: 0px solid #dddddd !important;
    
    margin-right: 35px !important;
    font-size: 2rem !important;
    margin-left: 0px !important;
  }

  main section div p {
    padding: 0px 0px;
    padding-left: 0px !important;
  }

  div.services {
   display: grid;
   grid-template-columns: 1fr !important;
  }

  div.services div {
    width: 80%;
  }

  .services p.service__icon {
    font-size: 2.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
  }

  .services p.service__title {
    font-size: 2rem !important;
    padding: 0;
    margin: 0;
  }

  .services p:last-of-type{
    padding: 0px 20px; 
    text-align: justify;
  }

  .gallery {
    /* grid-template-columns: 1fr; */
    width: 100%;
    grid-template-columns: 0 !important;
    height: 1500px !important;
    border: 1px solid #333333;
    margin: 150px auto 0px auto !important;
  }

  .gallery div {
    height: 200px;
  }

  .gallery div.gallery__item__one {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span !important;
   grid-column-end: 2 span !important;
  }

  .gallery div.gallery__item__two {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__three {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__four {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__five {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-top: 100px;
   padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__six {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-top: 100px;
   padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  main section form div label {
    margin-right:10px;
    border-radius: 5px;
  }

  main section form div:last-of-type input {
    padding: 10px;
    border-radius: 5px;
  }

  form {
    margin: -20px 0px 0px -35px !important;
    padding: 10px !important;
  }

  .form__h2 {
    margin-left: -22px !important;
  }

  footer {
    padding: 20px 0;
    text-align: center;
    display: grid !important;
    grid-template-columns: 1fr !important;
  }

  footer ul li a {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.8rem !important;
    font-weight: bold !important;
    margin-block-start: 0.2rem !important;
    margin-block-end: 0.2rem !important;
  }
  
  p {
    text-align: center !important;
  }
  
  .contact {
    display: none !important;
  }

}