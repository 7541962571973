body {
  margin: auto;
  width: 80%;
  height: 100%;
  padding: 0;
  font-family: sans-serif;
  background: #eeeeee;
  color: #111111;
  box-sizing: border-box;
}

p {
  display: block;
  text-align: center;
  padding: 10px;
}

.container {
  background-color: #ffffff;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}

h3 {
  font-size: 3rem;
}
/* ------------- Responsive Design ------------------- */

@media (min-width: 768px) and (max-width: 1024px) {
  body {
    margin: auto;
    width: 100%;
    height: 100%;
    padding: 0;
    font-family: sans-serif;
    background: #eeeeee;
    color: #111111;
    box-sizing: border-box;
  }

  .container {
    background-color: #ffffff;
    width: 100%;
    /* max-width: 1500px; */
    margin: 0 auto;
    box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}

  nav {
    text-align: center;
    display: grid;
    width: 100% !important;
    grid-template-columns: 1fr;
  }

  nav ul {
    width: 100% !important;
    display: flex !important;
    margin-bottom: 10px !important;
    padding: 0px !important;
    margin: -4px -77px 0px -50px !important;
  }

  nav ul:first-of-type li {
    font-size: 2rem !important;
    font-weight: bold;
    text-indent: 95px !important;
  }

  nav ul:last-of-type li {
   text-align: center;
   width: 100%;
   padding-top: 10px;
   margin: 0px 10px 0 0px !important;
  }

  nav ul li a {
    font-size: 1.2rem !important;
}

  header {
    background-size: 160%;
    height: 270px;
    position: absolute;
  }

  header div.top {
    width: 75%;
    grid-template-columns: 1fr;
    padding-top: 260px;
  }

  header div.top h1 {
    font-size: 3rem !important;
    border: none;
    padding: 20px;
    margin: 0 0 11px -40px !important;
    letter-spacing: 2px !important;
  }

  header div.top p {
    padding-left: 0 !important;
    display: none !important;
  }

  main section:first-of-type {
    margin-top: 390px;
  }

  main section {
    width: 90%;
    padding: 90px 0 !important;
    margin: 50px auto 0px auto;
    display: grid;
    grid-template-columns: 1fr !important;
  }

  main section h2 {
    border-right: 0px solid #dddddd !important;
    line-height: 0.2rem !important;
    margin-right: 35px !important;
    font-size: 3rem !important;
    margin-left: 0px !important;
  }

  main section div p {
    padding: 0px 0px;
    font-size: 1.2rem;
    padding-left: 0px !important;
  }

  div.services {
   display: grid;
   grid-template-columns: 1fr !important;
  }

  div.services div {
    width: 90%;
    height: 90%;
  }

  .services p.service__icon {
    font-size: 2.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
  }

  .services p.service__title {
    font-size: 2rem !important;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }

  .services p:last-of-type{
    padding: 0px 20px; 
    text-align: justify;
  }

  .gallery {
    /* grid-template-columns: 1fr; */
    width: 100%;
    grid-template-columns: 0 !important;
    height: 2165px !important;
    border: 1px solid #333333;
    margin: 150px auto 0px auto !important;
  }

  .gallery div {
    height: 200px;
  }

  .gallery div.gallery__item__one {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span !important;
   grid-column-end: 2 span !important;
  }

  .gallery div.gallery__item__two {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__three {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__four {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__five {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-top: 100px;
   padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__six {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-top: 100px;
   padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  main section form div label {
    margin-right:10px;
    border-radius: 5px;
  }

  main section form div:last-of-type input {
    padding: 10px;
    border-radius: 5px;
  }

  form {
    margin: 0px 0px 0px -25px !important;
    padding: 10px !important;
  }

  .form__h2 {
    margin-left: -22px !important;
  }

  footer {
    padding: 20px !important;
    text-align: center;
    display: grid !important;
    grid-template-columns: 1fr !important;
  }

  footer ul {
    margin: -15px !important;
  }

  footer ul li a {
    font-size: 4rem !important;
  }

  h3 {
    font-size: 2.5rem !important;
    font-weight: bold !important;
    -webkit-margin-before: 0.2rem !important;
            margin-block-start: 0.2rem !important;
    -webkit-margin-after: 0.2rem !important;
            margin-block-end: 0.2rem !important;
  }
  
  p {
    text-align: center !important;
    font-size: 1.2rem;
  }
  
  .contact {
    display: none !important;
  }

}

@media (min-width: 481px) and (max-width: 767px) {

  body {
    margin: auto;
    width: 100%;
    height: 100%;
    padding: 0;
    font-family: sans-serif;
    background: #eeeeee;
    color: #111111;
    box-sizing: border-box;
  }

  .container {
    background-color: #ffffff;
    width: 100%;
    /* max-width: 1500px; */
    margin: 0 auto;
    box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}

  nav {
    text-align: center;
    display: grid;
    width: 100% !important;
    grid-template-columns: 1fr;
  }

  nav ul {
    width: 100% !important;
    display: flex !important;
    margin-bottom: 10px !important;
    padding: 0px !important;
    margin: -4px -77px 0px -85px !important;
  }

  nav ul:first-of-type li {
    font-size: 2rem !important;
    font-weight: bold;
    text-indent: 95px !important;
  }

  nav ul:last-of-type li {
   text-align: center;
   width: 100%;
   padding-top: 10px;
   margin: 0px 10px 0 0px !important;
  }

  nav ul li a {
    font-size: 1.2rem !important;
}

  header {
    background-size: 160%;
    height: 270px;
    position: absolute;
  }

  header div.top {
    width: 75%;
    grid-template-columns: 1fr;
    padding-top: 260px;
  }

  header div.top h1 {
    font-size: 3rem !important;
    border: none;
    padding: 20px;
    margin: 0 0 11px -40px !important;
    letter-spacing: 2px !important;
  }

  header div.top p {
    padding-left: 0 !important;
    display: none !important;
  }

  main section:first-of-type {
    margin-top: 390px;
  }

  main section {
    width: 90%;
    padding: 90px 0 !important;
    margin: 50px auto 0px auto;
    display: grid;
    grid-template-columns: 1fr !important;
  }

  main section h2 {
    border-right: 0px solid #dddddd !important;
    line-height: 0.2rem !important;
    margin-right: 35px !important;
    font-size: 3rem !important;
    margin-left: 0px !important;
  }

  main section div p {
    padding: 0px 0px;
    font-size: 1.2rem;
    padding-left: 0px !important;
  }

  div.services {
   display: grid;
   grid-template-columns: 1fr !important;
  }

  div.services div {
    width: 90%;
    height: 90%;
  }

  .services p.service__icon {
    font-size: 2.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
  }

  .services p.service__title {
    font-size: 2rem !important;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }

  .services p:last-of-type{
    padding: 0px 20px; 
    text-align: justify;
  }

  .gallery {
    /* grid-template-columns: 1fr; */
    width: 100%;
    grid-template-columns: 0 !important;
    height: 2165px !important;
    border: 1px solid #333333;
    margin: 150px auto 0px auto !important;
  }

  .gallery div {
    height: 200px;
  }

  .gallery div.gallery__item__one {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span !important;
   grid-column-end: 2 span !important;
  }

  .gallery div.gallery__item__two {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__three {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__four {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__five {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-top: 100px;
   padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__six {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-top: 100px;
   padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  main section form div label {
    margin-right:10px;
    border-radius: 5px;
  }

  main section form div:last-of-type input {
    padding: 10px;
    border-radius: 5px;
  }

  form {
    margin: 0px 0px 0px -25px !important;
    padding: 10px !important;
  }

  .form__h2 {
    margin-left: -22px !important;
  }

  footer {
    padding: 20px !important;
    text-align: center;
    display: grid !important;
    grid-template-columns: 1fr !important;
  }

  footer ul {
    margin: -15px !important;
  }

  footer ul li a {
    font-size: 4rem !important;
  }

  h3 {
    font-size: 2.5rem !important;
    font-weight: bold !important;
    -webkit-margin-before: 0.2rem !important;
            margin-block-start: 0.2rem !important;
    -webkit-margin-after: 0.2rem !important;
            margin-block-end: 0.2rem !important;
  }
  
  p {
    text-align: center !important;
    font-size: 1.2rem;
  }
  
  .contact {
    display: none !important;
  }

}


@media (min-width: 1px) and (max-width: 480px)  {

  body {
    margin: auto;
    width: 100%;
    height: 100%;
    padding: 0;
    font-family: sans-serif;
    background: #eeeeee;
    color: #111111;
    box-sizing: border-box;
  }

  .container {
    background-color: #ffffff;
    width: 100%;
    /* max-width: 1500px; */
    margin: 0 auto;
    box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}

  nav {
    text-align: center;
    display: grid;
    width: 100% !important;
    grid-template-columns: 1fr;
  }

  nav ul {
    width: 100% !important;
    display: flex !important;
    margin-bottom: 10px !important;
    padding: 0px !important;
    margin: -4px -77px 0px -85px !important;
  }

  nav ul:first-of-type li {
    font-size: 1.2rem !important;
    font-weight: bold;
    text-indent: 95px !important;
    margin: 0px -50px 0 0px !important;
  }

  nav ul:last-of-type li {
   text-align: center;
   width: 100%;
   padding-top: 10px;
   margin: 0px 10px 0 0px !important;
  }

  nav ul li a {
    font-size: 0.9rem !important;
}

  header {
    background-size: 160%;
    height: 270px;
    position: absolute;
  }

  header div.top {
    width: 75%;
    grid-template-columns: 1fr;
    padding-top: 260px;
  }

  header div.top h1 {
    font-size: 2rem !important;
    border: none;
    padding: 58px;
    margin: 0 0 0 -65px !important;
    letter-spacing: -1px !important;
  }

  header div.top p {
    padding-left: 0 !important;
    display: none !important;
  }

  main section:first-of-type {
    margin-top: 390px;
  }

  main section {
    width: 90%;
    padding: 90px 0 !important;
    margin: 50px auto 0px auto;
    display: grid;
    grid-template-columns: 1fr !important;
  }

  main section h2 {
    border-right: 0px solid #dddddd !important;
    
    margin-right: 35px !important;
    font-size: 2rem !important;
    margin-left: 0px !important;
  }

  main section div p {
    padding: 0px 0px;
    padding-left: 0px !important;
  }

  div.services {
   display: grid;
   grid-template-columns: 1fr !important;
  }

  div.services div {
    width: 80%;
  }

  .services p.service__icon {
    font-size: 2.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
  }

  .services p.service__title {
    font-size: 2rem !important;
    padding: 0;
    margin: 0;
  }

  .services p:last-of-type{
    padding: 0px 20px; 
    text-align: justify;
  }

  .gallery {
    /* grid-template-columns: 1fr; */
    width: 100%;
    grid-template-columns: 0 !important;
    height: 1500px !important;
    border: 1px solid #333333;
    margin: 150px auto 0px auto !important;
  }

  .gallery div {
    height: 200px;
  }

  .gallery div.gallery__item__one {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span !important;
   grid-column-end: 2 span !important;
  }

  .gallery div.gallery__item__two {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__three {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__four {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__five {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-top: 100px;
   padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  .gallery div.gallery__item__six {
   background-repeat: no-repeat !important;
   background-size: cover !important;
   /* padding-top: 100px;
   padding-bottom: 100px; */
   grid-row-end: 2 span;
   grid-column-end: 2 span;
  }

  main section form div label {
    margin-right:10px;
    border-radius: 5px;
  }

  main section form div:last-of-type input {
    padding: 10px;
    border-radius: 5px;
  }

  form {
    margin: -20px 0px 0px -35px !important;
    padding: 10px !important;
  }

  .form__h2 {
    margin-left: -22px !important;
  }

  footer {
    padding: 20px 0;
    text-align: center;
    display: grid !important;
    grid-template-columns: 1fr !important;
  }

  footer ul li a {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.8rem !important;
    font-weight: bold !important;
    -webkit-margin-before: 0.2rem !important;
            margin-block-start: 0.2rem !important;
    -webkit-margin-after: 0.2rem !important;
            margin-block-end: 0.2rem !important;
  }
  
  p {
    text-align: center !important;
  }
  
  .contact {
    display: none !important;
  }

}
nav {
  position: fixed;
  display: grid;
  grid-template-columns: 2fr 1fr;
  background: #ffffff;
  width: 80%;
  z-index: 99;
  margin: 0 auto;
  padding: 0px 0;
  box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}

nav ul {
  width: 100%;
  display: flex;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0; 
}

nav li {
  margin: 0 100px 0 0; 
  font-size: 1.2rem;
}

nav ul:first-of-type {
  grid-template-columns: 1fr;
}

nav ul:first-of-type li {
  font-size: 2.2rem;
  font-weight: bold;
  text-indent: 30px;
}

nav ul:first-of-type li span {
  font-weight: 500;
  color: #444444;
}

nav ul:nth-last-of-type {
  grid-template-columns: repeat(3, 1fr);
}

nav ul li a {
  color: #111111;
  text-decoration: none;
  font-weight: bold;
  display: block;
}

nav ul li a:hover {
  color: rgb(233, 138, 59);
}

.navigation {
  text-align: center;
  color: rgb(233, 138, 59);
}

header {
  background: url(/static/media/background.93a7ff65.jpeg);
  background-size: cover;
  background-position: center;
  /* position: relative; */
  /* height: 700px; */
  width: 100%;
  height: auto;
}

header div.top {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto;
  z-index: 2;
  width: 80%;
  color: #222222;
  padding-top: 16%;
  padding-bottom: 16%;
  align-items: center;
}

header div.top h1 {
  font-size: 5rem;
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 5px;
  /* border-right: 2px solid #111111; */

}

header div.top p {
  padding-left: 30px;
}

.contact {
  background: rgb(255, 150, 0);
  color: #ffffff;
  font-weight: bold;
  display: block;
  margin: 0 auto;
  padding: 12px;
  text-align: center;
  text-decoration: none;
  width: 50%;
  border-radius: 10px;
  box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}
footer {
  background: rgb(255, 150, 0);
  color: #ffffff;

  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  align-items: center;
  padding: 10px;
  margin-top: 80px;
}

footer ul {
  list-style: none;
  justify-items: center;
  display: grid;
  padding: 0;
  margin: 0;
  grid-template-columns: 1fr 1fr 1fr;
}

footer ul li a {
  color: #ffffff;
  font-size: 3.5rem;
}

footer p {
  color: #ffffff;
  font-size: 1.2rem;
}
/*------------- Main -------------------*/

.about {
  height: auto;
}

main {
  position: relative;
  overflow: hidden;
  z-index: 3;
}

main section {
  display: grid;
  padding: 90px 0;
  width: 70%;
  align-items: center;
  grid-template-columns: 2fr 3fr;
  margin: 50px auto 150px auto;
}

main section h2 {
  color: #dddddd;
  font-size: 4.3rem;
  line-height: 4.2rem;
  padding-right: 10px;
  text-transform: uppercase;
  border-right: 3px solid #dddddd;
}

main section div {
  position: relative;
}

main section div p {
  padding-left: 0px;
  text-align: justify !important;
}

/*------------- Services -------------------*/

div.services {
  display: grid;
  padding: 10px;
  height: auto;
  grid-template-columns: repeat(3,1fr);
}

.services div {
  text-align: center;
  width: 90%;
  background: #ffffff;
  border-radius: 10px;
  margin: 10px auto 50px auto;
  box-shadow: 0px 10px 30px -9px rgba(0,0,0,0.75);
}

.services p.service__icon {
  font-size: 3.4rem;
  text-align: left !important;
  color: rgb(255, 150, 0);
  margin: 0 0 30px 0;
  padding: 30px 0 30px 90px;
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
  border-bottom: 2px solid rgb(255, 150, 0);
}

.services p.service__title {
  font-size: 3rem;
  margin: 20px;
  padding: 0;
}

.services p.service__title p {
  margin: 20px;
  text-align: justify !important;
}

.services__one {
  margin: 0;
}

.services__two {
  margin: 0;
}

.services__three {
  margin: 0;
}

/*------------- Gallery -------------------*/

.gallery {
  display: grid;
  width: 95%;
  height: 820px;
  grid-template-columns: repeat(3, 1fr);
  margin: 180px auto 0 auto;
  box-shadow: 0px 10px 20px -11px rgba(0,0,0,0.9);
}

.gallery div {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-filter: grayscale(83%);
          filter: grayscale(83%);
}

.gallery div.gallery__item__one {
  background: url(/static/media/1.01392dc7.jpeg);
  background-size: 160%;
  grid-row-end: span 2;
  grid-column-end: span 2;
}

.gallery div.gallery__item__two {
  background: url(/static/media/2.1336da1a.jpeg);
  background-size: 160%;
}

.gallery div.gallery__item__three {
  background: url(/static/media/3.6fa60f37.jpeg);
  background-size: 160%;
}

.gallery div.gallery__item__four {
  background: url(/static/media/4.5deb61c2.jpeg);
  background-size: 160%;
}

.gallery div.gallery__item__five {
  background: url(/static/media/logo.0a2d1778.jpeg);
  background-size: 160%;
}

.gallery div.gallery__item__six {
  background: url(/static/media/background.93a7ff65.jpeg);
  background-size: 160%;
}

.gallery__item__one:hover, 
.gallery__item__two:hover,
.gallery__item__three:hover,
.gallery__item__four:hover,
.gallery__item__five:hover,
.gallery__item__six:hover {
  background-size: 160%;
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}
main section form {
  margin: 0;
  padding: 0;
}

main section h2 {
  font-size: 3rem;
  margin-right: 20px;
}

main section form div {
  display: grid;
  padding: 10px;
  font-size: 1.1rem;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  grid-template-columns: 1fr 3fr;
}

main section form div:last-of-type {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

main section form div:last-of-type input {
  cursor: pointer;
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  background: rgb(255, 150, 0);
  box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}

main section form div label {
  background: #444444;
  color: #ffffff;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  padding: 6px;
  margin-right: 10px;
  border-radius: 10px;
  box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
}

main section form div input {
  font-size: 1rem;
}

.error {
  color: rgb(255, 57, 57)
}
