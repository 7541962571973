footer {
  background: rgb(255, 150, 0);
  color: #ffffff;

  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  align-items: center;
  padding: 10px;
  margin-top: 80px;
}

footer ul {
  list-style: none;
  justify-items: center;
  display: grid;
  padding: 0;
  margin: 0;
  grid-template-columns: 1fr 1fr 1fr;
}

footer ul li a {
  color: #ffffff;
  font-size: 3.5rem;
}

footer p {
  color: #ffffff;
  font-size: 1.2rem;
}