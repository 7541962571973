header {
  background: url('../images/background.jpeg');
  background-size: cover;
  background-position: center;
  /* position: relative; */
  /* height: 700px; */
  width: 100%;
  height: auto;
}

header div.top {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto;
  z-index: 2;
  width: 80%;
  color: #222222;
  padding-top: 16%;
  padding-bottom: 16%;
  align-items: center;
}

header div.top h1 {
  font-size: 5rem;
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 5px;
  /* border-right: 2px solid #111111; */

}

header div.top p {
  padding-left: 30px;
}

.contact {
  background: rgb(255, 150, 0);
  color: #ffffff;
  font-weight: bold;
  display: block;
  margin: 0 auto;
  padding: 12px;
  text-align: center;
  text-decoration: none;
  width: 50%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}