main section form {
  margin: 0;
  padding: 0;
}

main section h2 {
  font-size: 3rem;
  margin-right: 20px;
}

main section form div {
  display: grid;
  padding: 10px;
  font-size: 1.1rem;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  grid-template-columns: 1fr 3fr;
}

main section form div:last-of-type {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

main section form div:last-of-type input {
  cursor: pointer;
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  background: rgb(255, 150, 0);
  -webkit-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}

main section form div label {
  background: #444444;
  color: #ffffff;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  padding: 6px;
  margin-right: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
}

main section form div input {
  font-size: 1rem;
}

.error {
  color: rgb(255, 57, 57)
}